import { Link } from 'gatsby';
import React from 'react';
import { useLocation } from '@reach/router';
import AccountIcon from '../../icons/account';
import tailwindConfig from '../../../tailwind.config';
import DocumentIcon from '../../icons/document';

export const BASE_ACCOUNT_PATH = '/account';
export const PROFILE_PATH = BASE_ACCOUNT_PATH;
export const ADDRESS_PATH = `${BASE_ACCOUNT_PATH}/addresses`;
export const PASSWORD_SETTING_PATH = `${BASE_ACCOUNT_PATH}/reset-password`;
export const COMPANY_PATH = `${BASE_ACCOUNT_PATH}/company`;
export const ORDERS_PATH = '/orders';

const resolveConfig = require('tailwindcss/resolveConfig');
const config = resolveConfig(tailwindConfig);

const activeColor = config.theme.colors.primary['6'];
const defaultColor = config.theme.colors.light.title;

const AccountLayout = ({ children }) => {
  const location = useLocation();
  const path = location.pathname;

  const getColor = (pathName: string) =>
    path.startsWith(pathName) ? '!text-primary-6' : '!text-light-title';

  return (
    <div className="flex-col md:flex-row flex overflow-hidden">
      <div className="w-full md:w-[240px] md:min-w-[200px] h-fit bg-neutral-1 mb-6 md:mb-0 rounded-[6px] shadow-small border-light-divider border md:mr-6">
        <Link
          to={PROFILE_PATH}
          className="hover:!text-primary-6 px-4 py-2 border-b flex items-center font-semibold border-light-divider"
        >
          <AccountIcon
            color={
              path.startsWith(BASE_ACCOUNT_PATH) ? activeColor : defaultColor
            }
          />
          <div className={`ml-2 ${getColor(BASE_ACCOUNT_PATH)}`}>
            บัญชีของฉัน
          </div>
        </Link>
        <div className="bg-light-lighter p-4 pl-12 flex flex-col">
          <Link
            className={`hover:!text-primary-6 mb-4 ${
              '/account' === path ? '!text-primary-6' : '!text-light-title'
            }`}
            to={PROFILE_PATH}
          >
            ข้อมูลส่วนตัว
          </Link>
          <Link
            className={`hover:!text-primary-6 mb-4 ${getColor(ADDRESS_PATH)}`}
            to={ADDRESS_PATH}
          >
            ที่อยู่จัดส่ง
          </Link>
          <Link
            className={`hover:!text-primary-6 mb-4 ${getColor(
              PASSWORD_SETTING_PATH
            )}`}
            to={PASSWORD_SETTING_PATH}
          >
            ตั้งค่ารหัสผ่าน
          </Link>

          <Link
            className={`hover:!text-primary-6 ${getColor(COMPANY_PATH)}`}
            to={COMPANY_PATH}
          >
            สมาชิกบริษัท
          </Link>
        </div>
        <div className="px-4 py-2 border-t font-semibold border-light-divider">
          <Link
            className={`hover:!text-primary-6 flex items-center ${getColor(
              ORDERS_PATH
            )}`}
            to={ORDERS_PATH}
          >
            <DocumentIcon
              color={path.startsWith(ORDERS_PATH) ? activeColor : defaultColor}
            />{' '}
            <div className="ml-2">ประวัติการสั่งซื้อ</div>
          </Link>
        </div>
      </div>
      <div className="flex-1 overflow-hidden">{children}</div>
    </div>
  );
};

export default AccountLayout;
